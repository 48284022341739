<template>
    <div class="main-wrapper">
        <t-layout>
            <t-header>
                <headTop></headTop>
            </t-header>
            <t-content>
                <diaLog></diaLog>
                <router-view />
            </t-content>
            <t-footer>
                <footBot />
            </t-footer>
        </t-layout>
    </div>
</template>

<script setup lang="ts">
import headTop from "@/components/headTop.vue"
import footBot from "@/components/footBot.vue"
import diaLog from "@/components/diaLog.vue"
import { ref, onMounted } from 'vue';

const isMobile = ref(false);
import { useUserStore } from './store/index'
const usestore = useUserStore()
onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    if (!localStorage.getItem('token')) {
        usestore.visible = true
    }
});

function checkMobile() {
    isMobile.value = window.innerWidth <= 1024;
}
</script>

<style lang="scss">
#app {}

@media (max-width: 768px) {
    body {
        font-size: 16px;
        max-width: 1392px;
        margin: 0 auto;
        background: linear-gradient(90deg, #e883b5, #925891, #844abf);
        color: #fff;
        padding: 0;
        box-sizing: border-box;
        background-repeat: repeat-y;
        background-position: top -40vw left 0;
        background-size: 100% auto;

        .t-layout {
            background-color: rgba(255, 255, 255, 0);
        }

        .t-layout__header {
            background-color: rgba(255, 255, 255, 0);
            color: white;
            height: 50px;
        }

        .t-layout__content {

            background-color: rgba(255, 255, 255, 0);
            color: white;
        }

        .t-layout__footer {
            background-color: rgba(255, 255, 255, 0);
            color: white;
            padding: 0;
        }
    }
}

@media (min-width: 769px) {
    body {
        font-size: 16px;
        max-width: 1392px;
        margin: 0 auto;
        // background: #000;
        background: linear-gradient(90deg, #e883b5, #925891, #844abf);
        color: white;
        padding: 0;
        box-sizing: border-box;
        // background-image: url('https://pro.wildberries.ru/pro/ru/_next/static/media/md.68821a9d.svg');
        background-repeat: repeat-y;
        background-position: top -40vw left 0;
        background-size: 100% auto;
        // margin: 0 auto;

        .t-layout {
            background-color: rgba(255, 255, 255, 0);
        }

        .t-layout__header {
            background-color: rgba(255, 255, 255, 0);
            color: white;
            height: 100px;
        }

        .t-layout__content {

            background-color: rgba(255, 255, 255, 0);
            color: white;
        }

        .t-layout__footer {
            background-color: rgba(255, 255, 255, 0);
            color: white;
            padding: 0;
        }
    }

}

nav {}
</style>
