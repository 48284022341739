<template>
  <div :class="{ 'wrapper-mobile': isMobile, 'wrapper-pc': !isMobile }">
    <div class="boxs">
      <t-dialog :closeBtn="false" :footer="false" width="35%" header v-model:visible="usestore.visible">
        <div class="dialog">
          <p style="font-size: 35px; font-weight: 500;">WB商家服务中心</p>
          <!-- 获取验证码 -->
          <div class="dialog_one" v-if="toggle == false">
            <p>登录或创建个人资料</p>
            <t-form ref="form" :data="formData" :rules="rules">
              <t-form-item name="mobile">
                <t-input placeholder="请输入手机号" v-model="formData.mobile"></t-input>
              </t-form-item>
            </t-form>

            <t-button @click="GetCode" class="logbtn">获取验证码</t-button>
            <t-checkbox @change="checkboxs" v-model="checkbox">
              <sapn style="color: #424542;">我同意个人数据的处理和门户使用规则有关数据处理的更多信息</sapn>
            </t-checkbox>
          </div>
          <!-- 登录 -->
          <div class="dialog_one" v-else>
            <p>验证码已发送</p>
            <t-form ref="form" :data="forms" :rules="rules">
              <t-form-item name="code">
                <t-input placeholder="请输入公司备注信息" v-model="forms.info"></t-input>
              </t-form-item>
              <t-form-item name="code">
                <t-input placeholder="请输入验证码" :maxlength="6" @keyup="Validate" v-model="forms.codes"></t-input>
              </t-form-item>
            </t-form>
            <t-button @click="GetCodes" :disabled="disabled" class="logbtn">重新获取验证码</t-button>
            <div :disabled="disabled" style="color: #424542;">可通过{{ text }}秒重新发送代码</div>
          </div>
        </div>
        <div></div>
      </t-dialog>
    </div>
    <div class="box">
      <t-dialog :closeBtn="false" :footer="false" width="100%" header v-model:visible="usestore.visible">
        <div class="dialog">
          <!-- <img src="https://pro.wildberries.ru/pro/ru/_next/static/media/logo-full.64c81e2d.svg" alt /> -->
          <p>WB商家服务中心</p>
          <!-- 获取验证码 -->
          <div class="dialog_one" v-if="toggle == false">
            <p>登录或创建个人资料</p>
            <t-form ref="form" :data="formData" :rules="rules">
              <t-form-item name="mobile">
                <t-input placeholder="请输入手机号" v-model="formData.mobile"></t-input>
              </t-form-item>
            </t-form>

            <t-button @click="GetCode" class="logbtn">获取验证码</t-button>
            <t-checkbox @change="checkboxs" v-model="checkbox">
              <sapn style="color: #424542;">我同意个人数据的处理和门户使用规则有关数据处理的更多信息</sapn>
            </t-checkbox>
          </div>
          <!-- 登录 -->
          <div class="dialog_one" v-else>
            <p>验证码已发送</p>
            <t-form ref="form" :data="forms" :rules="rules">
              <t-form-item name="code">
                <t-input placeholder="请输入公司备注信息" v-model="forms.info"></t-input>
              </t-form-item>
              <t-form-item name="code">
                <t-input placeholder="请输入验证码" :maxlength="6" @keyup="Validate" v-model="forms.codes"></t-input>
              </t-form-item>
            </t-form>
            <t-button @click="GetCodes" :disabled="disabled" class="logbtn">重新获取验证码</t-button>
            <div :disabled="disabled" style="color: #424542;">可通过{{ text }}秒重新发送代码</div>
          </div>
        </div>
        <div></div>
      </t-dialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { verificationCode, LogIn } from '../api/api'
import { ref, reactive, onMounted } from 'vue'
import { MessagePlugin } from 'tdesign-vue-next'
import { useUserStore } from '../store/index'

const usestore = useUserStore()
const checkbox = ref(false)
const toggle = ref(false)
const text = ref('发送验证码')
const time = ref(60)
const disabled = ref(false)
const formData = reactive({
  mobile: '',
  type: 'login',
  uuid: '123456',
  captcha: '123456',
})
const forms = reactive({
  codes: '',
  info: ''
})
const rules = {
  mobile: [
    {
      required: true,
      message: '输入完整的手机号',
      type: 'error',
      trigger: 'blur',
    },
    { min: 11, message: '输入完整的手机号', type: 'error', trigger: 'blur' },
    { max: 11, message: '输入完整的手机号', type: 'error', trigger: 'blur' },
  ],
}
const isMobile = ref(false)
onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});
function checkMobile() {
  isMobile.value = window.innerWidth <= 1024;
}
// 获取验证码
async function GetCode() {
  if (checkbox.value != false) {
    var reg = /^1[345789]\d{9}$/
    if (!reg.test(formData.mobile)) {
      MessagePlugin.error('请输入正确的手机号')
      return false
    }
    sessionStorage.setItem('mobile', formData.mobile)
    verificationCode(formData)
      .then((res) => {
        if (res.code != 1) {
          MessagePlugin.error('发送失败')
          return false
        }
        toggle.value = true
        MessagePlugin.success('发送成功')
        disabled.value = true
        time.value = 60
        const si = setInterval(() => {
          time.value--
          text.value = `${time.value}`
          if (time.value === 0) {
            disabled.value = false
            text.value = '发送验证码'
            clearInterval(si)
          }
        }, 1000)
      })
      .catch((error) => {
        MessagePlugin.error(error.response.data.message)
      })
  } else {
    MessagePlugin.error('请查看相关规则并勾选')
  }
}
// 重新获取验证码
async function GetCodes() {
  verificationCode({ mobile: sessionStorage.getItem('mobile'), type: formData.type, uuid: formData.uuid, captcha: formData.captcha })
    .then((res) => {
      if (res.code != 1) {
        MessagePlugin.error('发送失败')
        return false
      }
      toggle.value = true
      MessagePlugin.success('发送成功')
      disabled.value = true
      time.value = 60
      const si = setInterval(() => {
        time.value--
        text.value = `${time.value}`
        if (time.value === 0) {
          disabled.value = false
          text.value = '发送验证码'
          clearInterval(si)
        }
      }, 1000)
    })
    .catch((error) => {
      MessagePlugin.error(error.response.data.message)
    })
}
// 登录
const Validate = () => {
  if (forms.codes.length >= 6) {
    // console.log(forms.codes);
    LogIn({ mobile: sessionStorage.getItem('mobile'), code: forms.codes, info: forms.info }).then((res) => {
      if (res.code == 1) {
        usestore.visible = false
        usestore.data.access_token = res.data.token.access_token
        localStorage.setItem('token', res.data.token.access_token)
        window.location.reload();
        MessagePlugin.success('登陆成功')

      } else {
        MessagePlugin.error(res.msg)
      }
      console.log(res)
    })
  }
}
</script>
<style lang="scss" scoped>
.wrapper-pc {
  .box {
    display: none;
  }

  .dialog {
    display: flex;
    align-items: center;
    flex-direction: column;

    .dialog_one {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      color: #000;
    }

    .logbtn {
      width: 100%;
      margin: 30px 0;
      background: #7001fd;
      border: 0;
      font-size: 24px;
      line-height: 32px;
      border-radius: 10px;
      padding: 28px 35px;
      box-sizing: border-box;
    }
  }

  ::v-deep(.t-checkbox.t-is-checked .t-checkbox__input) {
    background: #7001fd !important;
    border: #7001fd solid 1px;
  }

  ::v-deep(.t-form__controls) {
    margin-left: 0px !important;
  }

  ::v-deep(.t-input) {
    width: 380px !important;
    height: 50px !important;
    border-radius: 15px;
  }

  ::v-deep(.t-form__label) {
    display: none;
  }
}

.wrapper-mobile {
  .boxs {
    display: none;
  }

  .dialog {
    display: flex;
    align-items: center;
    flex-direction: column;

    .dialog_one {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      color: #000;
    }

    .logbtn {
      width: 100%;
      margin: 30px 0;
      background: #7001fd;
      border: 0;
      font-size: 24px;
      line-height: 32px;
      border-radius: 10px;
      padding: 28px 35px;
      box-sizing: border-box;
    }
  }

  ::v-deep(.t-checkbox.t-is-checked .t-checkbox__input) {
    background: #7001fd !important;
    border: #7001fd solid 1px;
  }

  ::v-deep(.t-form__controls) {
    margin-left: 0px !important;
  }

  ::v-deep(.t-input) {
    width: 100% !important;
    height: 50px !important;
    border-radius: 15px;
  }

  ::v-deep(.t-form__label) {
    display: none;
  }
}
</style>