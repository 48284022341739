import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "boxs" }
const _hoisted_2 = { class: "dialog" }
const _hoisted_3 = {
  key: 0,
  class: "dialog_one"
}
const _hoisted_4 = {
  key: 1,
  class: "dialog_one"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "box" }
const _hoisted_7 = { class: "dialog" }
const _hoisted_8 = {
  key: 0,
  class: "dialog_one"
}
const _hoisted_9 = {
  key: 1,
  class: "dialog_one"
}
const _hoisted_10 = ["disabled"]

import { verificationCode, LogIn } from '../api/api'
import { ref, reactive, onMounted } from 'vue'
import { MessagePlugin } from 'tdesign-vue-next'
import { useUserStore } from '../store/index'


export default /*@__PURE__*/_defineComponent({
  __name: 'diaLog',
  setup(__props) {

const usestore = useUserStore()
const checkbox = ref(false)
const toggle = ref(false)
const text = ref('发送验证码')
const time = ref(60)
const disabled = ref(false)
const formData = reactive({
  mobile: '',
  type: 'login',
  uuid: '123456',
  captcha: '123456',
})
const forms = reactive({
  codes: '',
  info: ''
})
const rules = {
  mobile: [
    {
      required: true,
      message: '输入完整的手机号',
      type: 'error',
      trigger: 'blur',
    },
    { min: 11, message: '输入完整的手机号', type: 'error', trigger: 'blur' },
    { max: 11, message: '输入完整的手机号', type: 'error', trigger: 'blur' },
  ],
}
const isMobile = ref(false)
onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});
function checkMobile() {
  isMobile.value = window.innerWidth <= 1024;
}
// 获取验证码
async function GetCode() {
  if (checkbox.value != false) {
    var reg = /^1[345789]\d{9}$/
    if (!reg.test(formData.mobile)) {
      MessagePlugin.error('请输入正确的手机号')
      return false
    }
    sessionStorage.setItem('mobile', formData.mobile)
    verificationCode(formData)
      .then((res) => {
        if (res.code != 1) {
          MessagePlugin.error('发送失败')
          return false
        }
        toggle.value = true
        MessagePlugin.success('发送成功')
        disabled.value = true
        time.value = 60
        const si = setInterval(() => {
          time.value--
          text.value = `${time.value}`
          if (time.value === 0) {
            disabled.value = false
            text.value = '发送验证码'
            clearInterval(si)
          }
        }, 1000)
      })
      .catch((error) => {
        MessagePlugin.error(error.response.data.message)
      })
  } else {
    MessagePlugin.error('请查看相关规则并勾选')
  }
}
// 重新获取验证码
async function GetCodes() {
  verificationCode({ mobile: sessionStorage.getItem('mobile'), type: formData.type, uuid: formData.uuid, captcha: formData.captcha })
    .then((res) => {
      if (res.code != 1) {
        MessagePlugin.error('发送失败')
        return false
      }
      toggle.value = true
      MessagePlugin.success('发送成功')
      disabled.value = true
      time.value = 60
      const si = setInterval(() => {
        time.value--
        text.value = `${time.value}`
        if (time.value === 0) {
          disabled.value = false
          text.value = '发送验证码'
          clearInterval(si)
        }
      }, 1000)
    })
    .catch((error) => {
      MessagePlugin.error(error.response.data.message)
    })
}
// 登录
const Validate = () => {
  if (forms.codes.length >= 6) {
    // console.log(forms.codes);
    LogIn({ mobile: sessionStorage.getItem('mobile'), code: forms.codes, info: forms.info }).then((res) => {
      if (res.code == 1) {
        usestore.visible = false
        usestore.data.access_token = res.data.token.access_token
        localStorage.setItem('token', res.data.token.access_token)
        window.location.reload();
        MessagePlugin.success('登陆成功')

      } else {
        MessagePlugin.error(res.msg)
      }
      console.log(res)
    })
  }
}

return (_ctx: any,_cache: any) => {
  const _component_t_input = _resolveComponent("t-input")!
  const _component_t_form_item = _resolveComponent("t-form-item")!
  const _component_t_form = _resolveComponent("t-form")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_sapn = _resolveComponent("sapn")!
  const _component_t_checkbox = _resolveComponent("t-checkbox")!
  const _component_t_dialog = _resolveComponent("t-dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'wrapper-mobile': isMobile.value, 'wrapper-pc': !isMobile.value })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_t_dialog, {
        closeBtn: false,
        footer: false,
        width: "35%",
        header: "",
        visible: _unref(usestore).visible,
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_unref(usestore).visible) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _cache[15] || (_cache[15] = _createElementVNode("p", { style: {"font-size":"35px","font-weight":"500"} }, "WB商家服务中心", -1)),
            (toggle.value == false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[12] || (_cache[12] = _createElementVNode("p", null, "登录或创建个人资料", -1)),
                  _createVNode(_component_t_form, {
                    ref: "form",
                    data: formData,
                    rules: rules
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_t_form_item, { name: "mobile" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_t_input, {
                            placeholder: "请输入手机号",
                            modelValue: formData.mobile,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.mobile) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"]),
                  _createVNode(_component_t_button, {
                    onClick: GetCode,
                    class: "logbtn"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("获取验证码")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_t_checkbox, {
                    onChange: _ctx.checkboxs,
                    modelValue: checkbox.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((checkbox).value = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_sapn, { style: {"color":"#424542"} }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("我同意个人数据的处理和门户使用规则有关数据处理的更多信息")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onChange", "modelValue"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _cache[14] || (_cache[14] = _createElementVNode("p", null, "验证码已发送", -1)),
                  _createVNode(_component_t_form, {
                    ref: "form",
                    data: forms,
                    rules: rules
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_t_form_item, { name: "code" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_t_input, {
                            placeholder: "请输入公司备注信息",
                            modelValue: forms.info,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((forms.info) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_t_form_item, { name: "code" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_t_input, {
                            placeholder: "请输入验证码",
                            maxlength: 6,
                            onKeyup: Validate,
                            modelValue: forms.codes,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((forms.codes) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"]),
                  _createVNode(_component_t_button, {
                    onClick: GetCodes,
                    disabled: disabled.value,
                    class: "logbtn"
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("重新获取验证码")
                    ])),
                    _: 1
                  }, 8, ["disabled"]),
                  _createElementVNode("div", {
                    disabled: disabled.value,
                    style: {"color":"#424542"}
                  }, "可通过" + _toDisplayString(text.value) + "秒重新发送代码", 9, _hoisted_5)
                ]))
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", null, null, -1))
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_t_dialog, {
        closeBtn: false,
        footer: false,
        width: "100%",
        header: "",
        visible: _unref(usestore).visible,
        "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_unref(usestore).visible) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _cache[22] || (_cache[22] = _createElementVNode("p", null, "WB商家服务中心", -1)),
            (toggle.value == false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[19] || (_cache[19] = _createElementVNode("p", null, "登录或创建个人资料", -1)),
                  _createVNode(_component_t_form, {
                    ref: "form",
                    data: formData,
                    rules: rules
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_t_form_item, { name: "mobile" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_t_input, {
                            placeholder: "请输入手机号",
                            modelValue: formData.mobile,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formData.mobile) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"]),
                  _createVNode(_component_t_button, {
                    onClick: GetCode,
                    class: "logbtn"
                  }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("获取验证码")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_t_checkbox, {
                    onChange: _ctx.checkboxs,
                    modelValue: checkbox.value,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((checkbox).value = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_sapn, { style: {"color":"#424542"} }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("我同意个人数据的处理和门户使用规则有关数据处理的更多信息")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onChange", "modelValue"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[21] || (_cache[21] = _createElementVNode("p", null, "验证码已发送", -1)),
                  _createVNode(_component_t_form, {
                    ref: "form",
                    data: forms,
                    rules: rules
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_t_form_item, { name: "code" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_t_input, {
                            placeholder: "请输入公司备注信息",
                            modelValue: forms.info,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((forms.info) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_t_form_item, { name: "code" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_t_input, {
                            placeholder: "请输入验证码",
                            maxlength: 6,
                            onKeyup: Validate,
                            modelValue: forms.codes,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((forms.codes) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"]),
                  _createVNode(_component_t_button, {
                    onClick: GetCodes,
                    disabled: disabled.value,
                    class: "logbtn"
                  }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode("重新获取验证码")
                    ])),
                    _: 1
                  }, 8, ["disabled"]),
                  _createElementVNode("div", {
                    disabled: disabled.value,
                    style: {"color":"#424542"}
                  }, "可通过" + _toDisplayString(text.value) + "秒重新发送代码", 9, _hoisted_10)
                ]))
          ]),
          _cache[23] || (_cache[23] = _createElementVNode("div", null, null, -1))
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ], 2))
}
}

})