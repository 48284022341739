// 进行axios二次封装:使用请求与响应拦截器
import axios from "axios";
// 第一步:利用axios对象的create方法,去创建axios实例(其他的配置:基础路径、超时的时间)
import base_url from "./url";
import { useUserStore } from '../store/index'
const request = axios.create({
  baseURL: base_url.base_url, // 基础URL
  timeout: 5000, // 请求超时时间
}); 
// 请求拦截器
request.interceptors.request.use(config => {
  const userstore = localStorage.getItem('token')
  const access_token = userstore
    if (config.method == 'put' || config.method == 'post') {
        config.headers.Authorization = access_token
    }
    if (config.method == 'get' ) {
        config.headers.Authorization = access_token
    }
  // 可以在这里添加例如token等请求头
  return config;
}, error => {
  // 请求错误处理
  return Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use(response => {
  // 对响应数据做处理，例如只返回data部分
  return response.data
}, error => {
  // 响应错误处理
  return Promise.reject(error);
});

export default request;


