<template>
    <div :class="{ 'wrapper-mobile': isMobile, 'wrapper-pc': !isMobile }">
        <t-layout class="layout">
            <t-aside>
                <t-menu default-value="item1" theme="dark" @change="changeHandler" style="margin-right: 40px" router>
                    <t-menu-item v-for="(item, index) in option" :key="index" :value="item.value" :to="item.path">{{
                        item.content
                        }}</t-menu-item>
                </t-menu>
            </t-aside>
            <t-content class="content">
                <div class="content-box">
                    <RouterView></RouterView>
                </div>
            </t-content>
        </t-layout>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
const option = ref([
    { content: '视频列表', value: '1', path: '/mine/minevideo' },
    { content: '我的培训', value: '2', path: '/' },
    { content: '我的订单', value: '3', path: '/' },
    { content: '推荐计划', value: '4', path: '/' },
    { content: '帮助', value: '5', path: '/' },
])
const isMobile = ref(false);
onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
});
function checkMobile() {
    isMobile.value = window.innerWidth <= 1024;
}
</script>
<style lang="scss">
.wrapper-pc {
    .t-layout__sider {
        height: 60vh;
    }

    .content-box {
        // background: #ffffff;
        width: 100%;
        height: 100%;
        margin: 0 10px;
        border-radius: 10px;
        padding: 10px 0;
        box-sizing: border-box;
    }
}

.wrapper-mobile {
    .t-layout__sider {
        display: none;
    }

    .content-box {
        background-color: rgba(255, 255, 255, 0);
    }
}
</style>