import { defineStore } from 'pinia';
export const useUserStore = defineStore('user',{
    state:()=>({
        data:{
            access_token:''
        },
        visible:false,
        menu1Value:'',
        
    }),
    actions:{   
        
    },
    persist: {
        enabled:true
    },
})
