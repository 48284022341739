import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "content-box" }

import { ref, onMounted } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'MineList',
  setup(__props) {

const option = ref([
    { content: '视频列表', value: '1', path: '/mine/minevideo' },
    { content: '我的培训', value: '2', path: '/' },
    { content: '我的订单', value: '3', path: '/' },
    { content: '推荐计划', value: '4', path: '/' },
    { content: '帮助', value: '5', path: '/' },
])
const isMobile = ref(false);
onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
});
function checkMobile() {
    isMobile.value = window.innerWidth <= 1024;
}

return (_ctx: any,_cache: any) => {
  const _component_t_menu_item = _resolveComponent("t-menu-item")!
  const _component_t_menu = _resolveComponent("t-menu")!
  const _component_t_aside = _resolveComponent("t-aside")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_t_content = _resolveComponent("t-content")!
  const _component_t_layout = _resolveComponent("t-layout")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'wrapper-mobile': isMobile.value, 'wrapper-pc': !isMobile.value })
  }, [
    _createVNode(_component_t_layout, { class: "layout" }, {
      default: _withCtx(() => [
        _createVNode(_component_t_aside, null, {
          default: _withCtx(() => [
            _createVNode(_component_t_menu, {
              "default-value": "item1",
              theme: "dark",
              onChange: _ctx.changeHandler,
              style: {"margin-right":"40px"},
              router: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.value, (item, index) => {
                  return (_openBlock(), _createBlock(_component_t_menu_item, {
                    key: index,
                    value: item.value,
                    to: item.path
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.content), 1)
                    ]),
                    _: 2
                  }, 1032, ["value", "to"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_t_content, { class: "content" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_RouterView)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}
}

})